import Typography from "../../components/Typography";
import useWindowDimensions from "../../Utilities/useWindowDimensions";
// import titleLogo from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy.svg"
// import titleLogoMobile from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy-mobile.svg"

const IntroText = () => {
  const { width } = useWindowDimensions();
  let isMobile = false
  if(width<480){
    isMobile = true
  } 
  return (
    <>
     {/* <Typography variant="h1">
       IT'S TIME!
      </Typography> */}
      
      <h2>
      <div className="title-logo-container">
        <span>Spring 2025 cabi</span><br></br>Fashion Show
      </div>
      </h2>
      <br />
      <Typography variant="h3">
        3:15 P.M. Pacific | 6:15 p.m. Eastern | 12:15 p.m. U.K.
      </Typography>
      <div className="contain-tablet-down">
        <Typography variant="h4">
        Enter your name, email address, and the exclusive access code provided by your Stylist below.
        </Typography>
        <br />
      </div>
    </>
  );
};

export default IntroText;
