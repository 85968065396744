import React from "react";
import Typography from "../../components/Typography";
import useWindowDimensions from "../../Utilities/useWindowDimensions";
// import titleLogo from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy.svg"
// import titleLogoMobile from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy-mobile.svg"
import comingSoonDesktop from '../../assets/images/s25/coming-soon.svg'
import comingSoonMobile from '../../assets/images/s25/coming-soon-mobile.svg'
import lockup from '../../assets/images/s25/cabi-spring-2025.svg'

const IntroText = ({success}) => {
  const { width } = useWindowDimensions();
  // let isMobile = false
  // if(width<480){
  //   isMobile = true
  // } 
  return (
    <>

      {/* <img src={width <= 999 ? comingSoonMobile : comingSoonDesktop} className="coming-soon" alt="Coming Soon"></img> */}
      <Typography variant="h1">
        COMING SOON
      </Typography>
      <h2>
        {/* <img src={lockup} className="coming-soon" alt="Coming Soon"></img> */}
        Spring 2025 <span>cabi</span><br></br>Fashion Show
      </h2>
      {/* <br /> */}
      <Typography variant="h3">
        <strong>JANUARY 16, 2025</strong>
        <br />
        3:15 p.m Pacific | 6:15 p.m. Eastern | 11:15 p.m. U.K.
      </Typography>
      <div className="contain-tablet-down">
        {success? (<> </>) :
          (
            <>   
            <Typography variant="h4">
            <span></span>
            </Typography>
            </>
          )
        }
        {!success && <Typography variant="h4">
          By registering, you’re opting in to receive exclusive emails from cabi.{" "}
          {/* <a href="https://www.cabionline.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a> */}
        </Typography>}
      </div>
    </>
  );
};

export default IntroText;
