import { useState, useEffect } from "react";
import { postUserData, validateUserData } from "../../api";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import { emailIsValid } from "../../Utilities";
import useWindowDimensions from "../../Utilities/useWindowDimensions";
import CaptchaValidation from "../../components/CaptchaValidation";

const RegisterForm = ({onSuccess, validateByTime = false, parent}) => {
  const { width } = useWindowDimensions();
  const [isCaptchOpen, setIsCaptchaOpen] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified]= useState(false);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    access_code: ""
  });
  const [showError, setShowError] = useState(false);
  const [error, setError]= useState('');

  useEffect(()=> {
    if(error.length>0) {
      setError(false);
    }
  }, [user])


  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid =
      user.first_name.length > 0 &&
      user.last_name.length > 0 &&
      emailIsValid(user.email)
    if (isValid) {
      setShowError(false);
      !isCaptchaVerified ? setIsCaptchaOpen(true): submitUserData();
    } else {
      setShowError(true);
    }
  };

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const submitUserData = () => {
    setIsCaptchaVerified(true);
    const request = validateByTime ? validateUserData(user) : postUserData(user)
    request.then((response) => {
      onSuccess(response.message);
      setError('');
    }).catch((error)=> {
      setError(error?.response?.data?.message ? error?.response?.data?.message: "Something went wrong. Please try again later.");
    })
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputField
          name="first_name"
          label="FIRST NAME"
          onChange={handleChange}
          value={user.first_name}
          error={showError && user.first_name.length === 0}
          errorText="Please provide a valid first name"
        />
        <InputField
          name="last_name"
          label="LAST NAME"
          onChange={handleChange}
          value={user.last_name}
          error={showError && user.last_name.length === 0}
          errorText="Please provide a valid last name"
        />
        <InputField
          name="email"
          label="EMAIL ADDRESS"
          type="email"
          onChange={handleChange}
          value={user.email}
          error={showError && !emailIsValid(user.email)}
          errorText="Please provide a valid email"
        />
        {parent == "DayOfShow" && ( <InputField
          name="access_code"
          label="ACCESS CODE"
          type="password"
          onChange={handleChange}
          value={user.access_code}
          error={error.length>0 || showError}
          errorText={showError? "That access code is not valid. Please try again" : error}
        />  )}
        <InputField
          name="page"
          type="hidden"
          class="hidden"
          value={parent}
        />
        <br />
        <Button
          type="submit"
          loading={false}
          variant={width <= 999 ? "light" : "light"}>
          {parent==="DayOfShow" ? "ENTER THE SHOW" : "REGISTER"}
        </Button>
      </form>
      <CaptchaValidation
        isOpen={isCaptchOpen}
        close={() => setIsCaptchaOpen(false)}
        handleSuccess={submitUserData}
      />
    </>
  );
};

export default RegisterForm;
