import React from 'react'
import vslockup from '../../assets/images/f23/explore-the-fall-collection-lockup.svg'
import lockup from '../../assets/images/f24/shop-fall-2024-lockup.svg'
import "./collectionBanner.scss"

const Banner= ({page}) =>{

    return (
        <>
        <div id="banner">
            <div className="banner-text-container">
                <h3><span>Just Dropped:</span>Spring 2025<br></br> Collection</h3>
                <a className="banner-button" href="https://www.cabionline.com/collection/clothes/" target="_blank" rel="noreferrer">SHOP NOW</a>
            </div>
        </div>
        </>
    )
}

export default Banner