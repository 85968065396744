import { useEffect, useState } from "react";
import { getCaptcha, verifyCaptcha } from "../../api";
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";
import Button from "../Button";
import "./captchavalidation.scss";

const CaptchaValidation = ({ isOpen, close, handleSuccess }) => {
  const [imgSource, setImgSource] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getCaptchaImage();
    }
  }, [isOpen]);

  const getCaptchaImage = () => {
    getCaptcha().then((response) => {
      if (response?.result?.captchaImage) {
        setImgSource(response.result.captchaImage);
      } else {
        throw new Error("Captcha image not found in the response.");
      }
    })
    .catch((error) => {
      console.error("Error fetching captcha image:", error);
      // Optionally, display the error message in the UI
      setError(`Failed to fetch captcha image: ${error.message || 'Unknown error'}`);
    });
  };

  const handleClose = () => {
    setImgSource("");
    close();
    setCaptchaValue("");
    setError("");
  };

  const handleClick = () => {
    setLoading(true);
    verifyCaptcha(captchaValue)
      .then((response) => {
        if (response.result.captchaResult === "success") {
          handleValid();
        } else {
          handleInvalid();
        }
      })
      .finally(() => setLoading(false));
  };

  const handleValid = () => {
    setError("");
    handleSuccess();
    handleClose();
  };

  const handleInvalid = () => {
    getCaptchaImage();
    setError("Mismatch, try again");
  };

  return (
    <Modal isOpen={isOpen} close={handleClose}>
      <div className="captcha-container">
        <Typography variant="h4">
          <strong>Security Check</strong>
        </Typography>
        {error.length > 0 && <p className="captcha-error">{error}</p>}
        <Typography variant="p">
          Type the text in the box to continue
        </Typography>
        {imgSource.length > 0 && !loading && (
          <div className="capctha-image">
            <img src={`data:image/png;base64, ${imgSource}`} alt="captcha" />
          </div>
        )}
        <div className="captcha-action-container">
          <input
            name="captchaValue"
            onChange={(e) => {
              setCaptchaValue(e.target.value);
            }}
            value={captchaValue}
            autoFocus
          />
          <Button
            onClick={handleClick}
            variant="light"
            disabled={captchaValue.length === 0}
            loading={loading}
          >
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CaptchaValidation;
